@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .section-border {
    @apply border-b-[2px] border-b-headers;
  }
  .burger-menu {
    transition:
      opacity 0.5s ease-in-out,
      transform 0.5s ease-in-out;
  }

  .open {
    opacity: 1;
    transform: translateX(0);
  }

  .closed {
    opacity: 0;
    transform: translateX(-100%);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .fade-in {
    animation: fadeIn 1s ease-in-out;
  }
  .fade-out {
    animation: fadeOut 1s ease-in-out;
  }
  .link-button {
    @apply text-[25px] w-10 h-10 p-1 border-headers hover:border-2 rounded-full bg-gray-200 inline-flex justify-center items-center text-headers  hover:text-gray-400 hover:p-0 hover:scale-120 duration-300 cursor-pointer;
  }

  .rounded-15 {
    border-radius: 15px;
  }

  .bannerIcon {
    @apply w-16 h-16 bg-gray-200 bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:-translate-y-1 transition-all hover:text-bannerIconHover cursor-pointer duration-300;
  }
  .stackIcon {
    @apply w-16 h-16 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 transition-all hover:text-designColor cursor-default duration-300;
  }
  
  .bgOpacity {
    @apply bg-black bg-opacity-40;
  }
  .contactInput {
    @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300;
  }
  .contactTextArea {
    @apply w-full rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 py-2 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300 resize-none;
  }
  .resumeLi {
    @apply w-full h-20 bg-gray-400 bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
  }
  .resumeIcon {
    @apply w-auto h-16 bg-gray-400 bg-opacity-25 text-gray-200 text-xl inline-flex items-center justify-center rounded-md px-6 mb-12 lg:mb-0  hover:-translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300;
  }
  .active {
    @apply text-activeLink scale-105
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}
